import { Pipe, PipeTransform } from '@angular/core';
import { ThemeStageService } from '../services/theme-stage.service';

@Pipe({
  name: 'weightStage'
})
export class WeightStagePipe implements PipeTransform {

  constructor(
    private stageTheme: ThemeStageService
  ) { }

  transform(height: any, weight: any): any {
    return (this.stageTheme.getThemeStagesWeight({'height':height,'weight':weight}));
  }

}
