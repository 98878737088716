import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minute'
})
export class MinutePipe implements PipeTransform {

  transform(value: number): unknown {
    const minutes: number = Math.floor(value / 60);
    return minutes;
  }

}
