import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'csvkeytovalue'
})
export class CsvkeytovaluePipe implements PipeTransform {

  transform(value: any[], args: any[]): any [] {
    const r = [];
    args.forEach(item => {
      if (value.includes(item.value)) {
        r.push(item.text)
      }
    });
    return r;
  }
}
