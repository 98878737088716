import { Pipe, PipeTransform } from '@angular/core';
import { ThemeStageService } from '../services/theme-stage.service';

@Pipe({
  name: 'totalCholesterolStage'
})
export class TotalCholesterolStagePipe implements PipeTransform {

  constructor(
    private stageTheme: ThemeStageService
  ) { }

  transform(value: any, ...args: any[]): any {
    return (this.stageTheme.getThemeStagesTotalCholesterol(value));
  }

}
