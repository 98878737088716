import { Pipe, PipeTransform } from '@angular/core';
import { ThemeStageService } from '../services/theme-stage.service';

@Pipe({
  name: 'oxygenSaturationStage'
})
export class OxygenSaturationStagePipe implements PipeTransform {

  constructor(
    private stageTheme: ThemeStageService
  ) { }

  transform(value: any, ...args: any[]): any {
    return (this.stageTheme.getThemeStagesSPO2(value));
  }

}
