import { Pipe, PipeTransform } from '@angular/core';
import { ThemeStageService } from '../services/theme-stage.service';

@Pipe({
  name: 'ldlCholesterolStage'
})
export class LdlCholesterolStagePipe implements PipeTransform {

  constructor(
    private stageTheme: ThemeStageService
  ) { }

  transform(value: any, ...args: any[]): any {
    return (this.stageTheme.getThemeStagesLdlCholesterol(value));
  }

}
