<div *ngIf="isLoading; else completeLoading" class="container h-100">
    <div class="row no-gutters h-100 align-items-center">
        <div class="mx-auto">
            <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
            <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
        </div>
    </div>
</div>
<ng-template #completeLoading>
    <router-outlet></router-outlet>
</ng-template>