import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamicFilter'
})
export class DynamicFilterPipe implements PipeTransform {

  transform(items: any[], id: any): any[] {
    
    return items.filter((item) => {
      return item.id === parseInt(id);
    });
  }
}
