import { Pipe, PipeTransform } from '@angular/core';
import { PREFERRED_LANGUAGES } from '../preferred-languages';

@Pipe({
  name: 'preferredLanguage'
})
export class PreferredLanguagePipe implements PipeTransform {

  languages = PREFERRED_LANGUAGES;

  transform(lang: string, ...args: unknown[]): unknown {
    const foundIndex = this.languages.findIndex(x => x.name == lang);
    return (foundIndex > -1) ? this.languages[foundIndex].label : '--';
  }

}
